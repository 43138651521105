export default () => {
  // Skip error reporting on legacy browsers
  // (which don’t support Object.fromEntries).
  if (!("fromEntries" in Object)) {
    return;
  }

  const isDevEnvironment =
    location.hostname === "localhost" || /\.local$/i.test(location.hostname);

  let isSentryLoaded = false;

  const onFirstError = async (event: ErrorEvent) => {
    const Sentry = await import("@sentry/browser");

    !isSentryLoaded &&
      Sentry.init({
        dsn: "https://1aa59e1dc1d278a31ae9b3b2acd0b072@o179954.ingest.sentry.io/4505719625547776",
        enabled: !isDevEnvironment,
        autoSessionTracking: false,
        beforeSend: (event) => {
          const exception = event.exception?.values?.[0];
          if (!exception) return event;

          const fBError =
            exception.value === "Illegal invocation" &&
            location.search.indexOf("fbclid=") !== -1;

          return fBError ? null : event;
        },
      });

    isSentryLoaded = true;
    window.removeEventListener("error", onFirstError);

    Sentry.captureException(event.error);
  };

  window.addEventListener("error", onFirstError);
};
