export const initForm = () => {
  const fieldHidden = document.querySelector(".js-field-hidden");
  const formSubmit =
    document.querySelector<HTMLButtonElement>(".js-form-submit");

  fieldHidden?.addEventListener("keyup", (e) => {
    if (!formSubmit) return;
    const target = <HTMLInputElement>e.target;
    formSubmit.disabled = !!target.value;
  });
};
