interface UsercentricsEvent extends Event {
  detail: {
    event: "consent_status";
    Vimeo?: boolean;
  };
}

const getIsUsercentricsEvent = (event: Event): event is UsercentricsEvent =>
  (<UsercentricsEvent>event)?.detail?.event === "consent_status";

const addUsercentricsConsentEventListener = (
  callback: (event: UsercentricsEvent) => void
) =>
  window.addEventListener(
    "UsercentricsCustomEvent",
    (event) => getIsUsercentricsEvent(event) && callback(event)
  );

export const initVideo = () => {
  let isVimeoVideoConsented = false;

  addUsercentricsConsentEventListener((event) => {
    if (!event.detail.Vimeo || isVimeoVideoConsented) return;

    const allVimeoVideosContainer = document.querySelectorAll(
      ".js-video-container"
    );

    [...allVimeoVideosContainer].map((el) => {
      const iframe = el.querySelector<HTMLIFrameElement>(".js-video-iframe");
      const src = iframe?.dataset["src"];

      if (src) iframe.src = src;

      el.classList.add("active");
    });

    isVimeoVideoConsented = true;
  });
};
