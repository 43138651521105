import EmblaCarousel, {
  EmblaCarouselType,
  EmblaOptionsType,
} from "embla-carousel";

const handleNavigation = (
  carousel: EmblaCarouselType,
  leftArrowElement: HTMLButtonElement | null,
  rightArrowElement: HTMLButtonElement | null
) => {
  if (!leftArrowElement || !rightArrowElement) return;

  leftArrowElement.disabled = !carousel.canScrollPrev();
  rightArrowElement.disabled = !carousel.canScrollNext();
};

const addCarousel = (
  element: HTMLElement,
  options?: Partial<EmblaOptionsType>
) => {
  const carousel = EmblaCarousel(element, {
    align: "start",
    containScroll: "trimSnaps",
    ...options,
  });

  const leftArrowElement =
    document.querySelector<HTMLButtonElement>(".js-carousel-prev");
  const rightArrowElement =
    document.querySelector<HTMLButtonElement>(".js-carousel-next");

  leftArrowElement?.addEventListener("click", () => carousel.scrollPrev());
  rightArrowElement?.addEventListener("click", () => carousel.scrollNext());

  if (options?.loop) return;

  carousel.on("select", () =>
    handleNavigation(carousel, leftArrowElement, rightArrowElement)
  );
  carousel.on("init", () =>
    handleNavigation(carousel, leftArrowElement, rightArrowElement)
  );
};

export const initCarousels = () => {
  const departmentEl = document.querySelector<HTMLElement>(
    ".js-embla-department"
  );
  departmentEl && addCarousel(departmentEl);

  const processEl = document.querySelector<HTMLElement>(".js-embla-process");
  processEl && addCarousel(processEl);

  const employeeEl = document.querySelector<HTMLElement>(".js-embla-employee");
  employeeEl &&
    addCarousel(employeeEl, {
      loop: true,
    });
};
