interface WindowWithUsercentrics extends Window {
  UC_UI?: {
    showFirstLayer: () => void;
    showSecondLayer: () => void;
    isInitialized: () => boolean;
  };
}

export const initUsercentricsButtons = () => {
  document.addEventListener("click", (e) => {
    const target = <HTMLElement>e.target;
    const btn = target.closest('[href="#show-usercentrics-modal"]');

    if (!btn) return;

    e.preventDefault();
    (<WindowWithUsercentrics>(<unknown>window)).UC_UI?.showSecondLayer();
  });
};
