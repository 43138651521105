interface Job {
  id: string;
  background_image_url: string;
  url: string;
  title: string;
  locations: {
    id: number;
    name: string;
  }[];
  departments: {
    id: number;
    name: string;
  }[];
  published_at: string;
}

const mappingDepartmentIcons: any = {
  direktion: "direction",
  it: "it",
  "kommunikation & events": "communication",
  "people & culture": "people-culture",
  personalberatung: "personnel-consulting",
  rechtsabteilung: "legal-advice",
  steuerberatung: "tax-advice",
  unternehmensberatung: "management-consulting",
  "verwaltung/controlling": "internal-controlling",
  "web marketing": "webmarketing",
};

const fetchData = async <Response>(
  url: string
): Promise<Response | undefined> => {
  const response = await fetch(url, {
    method: "GET",
  });
  if (response.status !== 200) return;
  return await response.json();
};

const getJobsHTML = (data: Job[]) =>
  data
    ?.sort((a, b) =>
      new Date(b.published_at) < new Date(a.published_at) ? -1 : 1
    )
    .map((job) => {
      const departmentName = job.departments.shift()?.name.toLocaleLowerCase();
      const icon = departmentName && mappingDepartmentIcons[departmentName];

      const locations = job.locations.map((loc) => loc.name).join(", ");

      return `
        <a href="${job.url}" target="_blank" class="box jobs-box">
          <div class="jobs-icon-wrapper">
            <object type="image/svg+xml" data="/user/themes/karriere/svg/${icon}.svg" class="icon jobs-icon"></object>
          </div>
          <div class="jobs-content">
            <p class="jobs-location">${locations}</p>
            <h3 class="jobs-title">${job.title}</h3>
          </div>
          <div class="box-img-wrapper">
            <img
              src="${job.background_image_url}"
              class="box-img"
              loading="lazy"
              width="350"
              height="350"
            />
          </div>
        </a>
      `;
    })
    .join("");

export const initJobs = async () => {
  const jobsEl = document.querySelector(".js-jobs");

  if (!jobsEl) return;

  const data = await fetchData<Job[]>(
    "https://hgv.onboard.org/de/exports/v2/jobs.json"
  );

  const jobsHTML = data && getJobsHTML(data);

  if (!data) {
    jobsEl.insertAdjacentHTML(
      "afterbegin",
      '<div class="jobs-box-center"><p>Es ist ein Fehler augetreten</p></div>'
    );
    return;
  }

  if (!data.length) {
    jobsEl.insertAdjacentHTML(
      "afterbegin",
      '<div class="jobs-box-center"><p>Zurzeit keine Angebote verfügbar</p></div>'
    );
    return;
  }

  jobsEl.insertAdjacentHTML("afterbegin", jobsHTML ?? "");
};
