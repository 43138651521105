const handleScroll = () => {
  const header = document.querySelector<HTMLElement>(".js-header");

  const scrollTopOffset = window.scrollY || document.documentElement.scrollTop;

  scrollTopOffset
    ? header?.classList.add("scroll")
    : header?.classList.remove("scroll");
};

let lastScrollTop = 0;
const handleLogo = () => {
  const scrollTopOffset = window.scrollY || document.documentElement.scrollTop;
  const logo = document.querySelector(".js-logo");
  const path = "/user/themes/karriere/svg";

  if (!!lastScrollTop === !!scrollTopOffset) return;

  logo?.setAttribute(
    "data",
    `${path}${!scrollTopOffset ? "/hgv-logo.svg" : "/logo-dark.svg"}`
  );
  lastScrollTop = scrollTopOffset;
};

export const initScroll = () => {
  document.addEventListener("scroll", () => (handleScroll(), handleLogo()));
};
