const handleMenu = () => {
  const list = document.querySelector(".js-list");

  list?.classList.toggle("expand");
};

export const initMenu = () => {
  document.addEventListener("click", (e) => {
    const target = <HTMLElement>e.target;
    const btn = target.closest<HTMLButtonElement>(".js-nav-btn");

    btn && handleMenu();
    btn?.classList.toggle("active");
  });
};
